<div class="flex flex-row flex-wrap w-full">
  @if (link?.enabled) {
    <a
      [routerLink]="link?.enabled ? link?.url : '/'"
      routerLinkActive="selected"
      class="nav-link justify-start items-center w-full"
      [class.disabled]="!link?.enabled"
    >
      <i
        [class]="link?.icon?.length ? link.icon : 'fas fa-circle-notch'"
        class="ml-6"
      ></i>

      @if (isRecentPermitLink()) {
        <span class="nav-link-title ml-2">
          {{ link?.name }}
        </span>
      } @else {
        <span class="nav-link-title ml-2">
          {{ [link?.itemType, 'LEFT_NAV', link?.name] | translate }}
        </span>
      }
    </a>
  }

  @if (isLastSectionLink(link?.name)) {
    <div class="divider flex flex-row w-full"></div>
  }
</div>
