<div class="stil-leftnav flex flex-col justify-start items-center grow h-full">
  @for (navItem of enabledRoutes; track navItem.ID) {
    <a
      class="nav-block flex flex-row h-[40px]"
      (click)="navigateTo(navItem.route)"
    >
      @if (navItem.children) {
        <div
          class="nav-item flex flex-row justify-center items-center w-[40px]"
          [tp]="'RIGHT_NAV.' + navItem.name | translate"
          tpPlacement="left"
          [matMenuTriggerFor]="menu"
        >
          <i class="child-icon fas fa-{{ navItem.icon }}"></i>
        </div>

        <mat-menu #menu="matMenu">
          @for (link of navItem.children; track link.ID) {
            <a
              mat-menu-item
              [disabled]="link?.disabled"
              [routerLink]="link.route"
              class="hover:!text-stilRed-300"
            >
              <div class="flex flex-row flex-nowrap items-center w-full">
                <i class="menu-icon fas fa-{{ link.icon }} w-[25px]"></i>
                <span class="text-sm">{{ 'RIGHT_NAV.' + link.name | translate }}</span>
              </div>
            </a>
          }
        </mat-menu>
      } @else {
        <div
          class="nav-item p-1 w-[40px] flex flex-row justify-start items-center"
          [tp]="'RIGHT_NAV.' + navItem.name | translate"
          tpPlacement="left"
        >
          <i class="fas fa-{{ navItem.icon }} w-[25px]"></i>
        </div>
      }
    </a>
  }

  <div class="flex flex-col justify-end items-center grow">
    <i
      (click)="showSettings()"
      class="fas fa-gears h-[25px]"
    ></i>
  </div>
</div>
