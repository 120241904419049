import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TippyDirective } from '@ngneat/helipopper';
import { TranslatePipeModule } from '~shared/pipes/translate.pipe';
import { SharedModule } from '~shared/shared.module';

import { TranslationsComponent } from './components/translations/translations.component';


@NgModule({
  declarations: [ TranslationsComponent ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    TippyDirective,
    TranslatePipeModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class TranslationsModule { }
