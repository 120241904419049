import {
  Component, DestroyRef, OnInit, ViewEncapsulation,
  inject
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSelectChange } from '@angular/material/select';
import { uniqBy } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { AsyncSubject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AppState } from '~core/states/app/app.state';

import { STIL_LanguageDataService } from '../../../shared/services/apiSTILLanguageController';
import { STIL_LanguageTranslationDataService } from '../../../shared/services/apiSTILLanguageTranslationController';


@Component({
  selector: 'app-translations',
  templateUrl: './translations.component.html',
  styleUrls: [ './translations.component.scss' ],
  encapsulation: ViewEncapsulation.None
})
export class TranslationsComponent implements OnInit {

  private destroyRef = inject(DestroyRef);

  translations = [];
  translationsSubject = new AsyncSubject();
  settingCRUD;
  columns = [
    { prop: 'itemType' },
    { prop: 'formName' },
    { prop: 'controlName' },
    { prop: 'defaultCaption' },
    { prop: 'preferredCaption' }
  ];

  selectedLanguage = null;
  languages = [];
  isLoading = false;
  translateBasePath: string;

  constructor(
    private appState: AppState,
    private toastr: ToastrService,
    private languageDataService: STIL_LanguageDataService,
    private translationService: STIL_LanguageTranslationDataService
  ) { }

  ngOnInit() {
    this.appState.set('pageTitle', `Translations`);
    this.settingCRUD = this.getCRUDSettings();
    this.isLoading = true;
    this.languageDataService
      .getRecordsForParentID(this.appState.get('facilityId'), true)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((languages: any) => {
        this.languages = languages;
        if (languages.length) {
          this.selectedLanguage = languages[0];
          this.getData(this.selectedLanguage.ID);
        }
        this.isLoading = false;
      });
    this.translateBasePath = `NEW_Translations.General`;
  }

  getData(languageID) {
    this.isLoading = true;
    this.translationService
      .getRecordsForParentIDWithoutCache(languageID)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((translations: any) => {
        this.translations = translations;
        this.translationsSubject.next(translations);
        this.translationsSubject.complete();
        this.isLoading = false;
      });
  }

  onSelectionChange(selection: MatSelectChange) {
    this.selectedLanguage = selection.value;
    this.getData(this.selectedLanguage.ID);
  }

  initializeTranslations() {
    this.translationService.initializeTranslations(this.selectedLanguage.ID)
      .subscribe(res => {
        this.getData(this.selectedLanguage.ID);
      });
  }

  editRow([ row ]) {
    this.translationService
      .edit(row)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(editRes => {
        this.toastr.success('Record saved.');
      });
  }

  createRow(row) {
    delete row?.parentId;
    row.parentID = this.selectedLanguage.ID;
    this.translationService
      .create(row)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(createRes => {
        this.toastr.success('Record saved.');
        this.getData(this.selectedLanguage.ID);
      });
  }

  deleteRow([ row ]) {
    this.translationService
      .delete(row.ID)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(deleteRes => {
        this.toastr.success('Record removed.');
        this.getData(this.selectedLanguage.ID);
      });
  }

  getCRUDSettings() {
    return {
      entity: '',
      referenceProp: '',
      edit: {
        columnConfig: {
          PreferredCaption: {
            controlType: 'text'
          },
          itemType: {
            controlType: 'combobox',
            options: this.translationsSubject.asObservable()
              .pipe(switchMap((res: any) => {

                return of(uniqBy(res, 'itemType'));
              })),
            referenceProp: 'itemType'
          },
          FormName: {
            controlType: 'combobox',
            options: this.translationsSubject.asObservable()
              .pipe(switchMap((res: any) => {

                return of(uniqBy(res, 'FormName'));
              })),
            referenceProp: 'FormName'
          },
          ControlName: {
            controlType: 'text'
          },
          DefaultCaption: {
            controlType: 'text'
          }
        }
      },
      create: {
        columnConfig: {
          PreferredCaption: {
            controlType: 'text'
          },
          itemType: {
            controlType: 'combobox',
            options: this.translationsSubject.asObservable()
              .pipe(switchMap((res: any) => {

                return of(uniqBy(res, 'itemType'));
              })),
            referenceProp: 'itemType'
          },
          FormName: {
            controlType: 'combobox',
            options: this.translationsSubject.asObservable()
              .pipe(switchMap((res: any) => {

                return of(uniqBy(res, 'FormName'));
              })),
            referenceProp: 'FormName'
          },
          ControlName: {
            controlType: 'text'
          },
          DefaultCaption: {
            controlType: 'text'
          }
        }
      }
    };

  }

}
